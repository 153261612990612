var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row col-12 color_gris justify-center q-pb-lg"},[_c('div',{staticClass:"row col-12 col-md-10"},[_vm._m(0),(_vm.aBanners && _vm.aBanners.length > 0)?_c('div',{staticClass:"col-12 row justify-center q-mb-md"},[_c('div',{staticClass:"slideshow-container"},[_vm._l((_vm.aBanners),function(item){return _c('div',{key:item.id,class:'mySlides fade row ' +
              (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-mb-md' : 'q-px-md'),style:('border-radius: 20px;' +
              (_vm.$q.screen.xs ? 'height: 300px' : 'height: 366px;'))},[_c('img',{staticClass:"cursor-pointer xs-hide",style:('width: 100%; height: 100%;  ' +
                (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : 'border-radius: 20px;')),attrs:{"src":item.imagen},on:{"click":function($event){return _vm.toLink(item.link, '_self')}}}),_c('img',{staticClass:"cursor-pointer sm-hide md-hide lg-hide xl-hide",style:('width: 100%; height: 100%; ' +
                (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : 'border-radius: 20px;')),attrs:{"src":item.imagen_mobile},on:{"click":function($event){return _vm.toLink(item.link, '_self')}}})])}),_c('q-icon',{class:'cursor-pointer prev text-white bg-secondary ' +
              (_vm.$q.screen.xs ? 'q-ma-sm' : 'q-ma-xl'),staticStyle:{"font-size":"1.5rem","border-radius":"40px"},attrs:{"name":"keyboard_arrow_left"},on:{"click":function($event){return _vm.plusSlides(-1)}}}),_c('q-icon',{class:'cursor-pointer next bg-secondary text-white ' +
              (_vm.$q.screen.xs ? 'q-ma-sm' : 'q-ma-xl'),staticStyle:{"font-size":"1.5rem","border-radius":"40px"},attrs:{"name":"keyboard_arrow_right"},on:{"click":function($event){return _vm.plusSlides(1)}}})],2)]):_vm._e(),(_vm.aActividades.length > 0)?_c('div',{staticClass:"col-12 row justify-center q-mt-sm q-mb-lg"},[_c('div',{staticClass:"row justify-start col-12"},_vm._l((_vm.aActividades),function(actividad){return _c('div',{key:actividad.id,class:'row justify-center col-12 col-sm-6 col-md-3 q-mb-md  q-pa-md ' +
              (_vm.$q.screen.xs ? 'q-mt-xs ' : ' q-mt-none')},[_c('q-card',{staticClass:"full-width color_blanco justify-center cursor-pointer q-pa-sm",staticStyle:{"border-radius":"15px"},on:{"click":function($event){return _vm.irActividad(actividad.id, actividad.nombre_web)}}},[_c('q-img',{staticStyle:{"height":"160px","border-radius":"15px"},attrs:{"src":actividad.foto}}),_c('q-card-section',{staticClass:"q-px-md"},[_c('span',{staticClass:"absolute q-px-md q-ma-none color_secundario",staticStyle:{"bottom":"90px","left":"0px","transform":"translateY(-50%)","font-weight":"bold","border":"none","padding":"8px"}},[_vm._v(" "+_vm._s(actividad.categoria.nombre)+" ")]),_c('div',{staticClass:"row no-wrap"},[_c('div',{staticClass:"col text-bold texto_truncado text-secondary",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(actividad.nombre)+" ")])])]),_c('q-card-section',{staticClass:"q-py-none q-px-md",staticStyle:{"height":"80px"}},[_c('div',{staticClass:"texto_truncado3"},[_vm._v(" "+_vm._s(actividad.descripcion)+" ")])]),_c('q-card-actions',{staticClass:"q-px-md row justify-between bg-fecha",on:{"click":function($event){return _vm.irActividad(actividad.id, actividad.nombre_web)}}},[_c('div',{staticClass:"row"},[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"color":"secondary","name":"date_range","size":"sm"}}),_c('div',{staticClass:"text_secundario text-bold"},[_vm._v(" Inicio: "+_vm._s(_vm.formatFechaCard(actividad.fecha_inicio))+" ")])],1),_c('q-icon',{staticClass:"iconovisible",attrs:{"color":"secondary","name":"keyboard_arrow_right","size":"sm"}})],1)],1)],1)}),0)]):_c('div',{staticClass:"col-12 row"},[_c('div',{staticClass:"col-12 row justify-center q-mt-sm q-mb-lg"},[_c('div',{staticClass:"row justify-start col-12"},[_c('div',{class:'row justify-center col-12 col-sm-6 col-md-3 q-mb-md  q-pa-md ' +
                (_vm.$q.screen.xs ? 'q-mt-xs ' : ' q-mt-none')},[_vm._v(" Sin actividades registradas ")])])])]),(_vm.aActividades.length == 0)?_c('div',{staticClass:"row justify-center q-mt-lg q-mb-lg"}):_vm._e(),(_vm.aActividades.length > 0 && _vm.total > 8)?_c('div',{staticClass:"col-12 q-pa-lg flex flex-center"},[_c('q-pagination',{attrs:{"unelevated":"","min":1,"max":Math.ceil(_vm.total / 8),"max-pages":_vm.$q.screen.xs ? 1 : 7,"direction-links":true,"color":"secondary"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-my-md q-ml-md"},[_c('label',{staticClass:"text-secondary text-h4",staticStyle:{"font-weight":"700"}},[_vm._v(" Actividades ")])])}]

export { render, staticRenderFns }