var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row col-12 color_gris justify-center q-py-lg"},[_c('div',{staticClass:"row col-12 col-md-10"},[_c('div',{class:'q-mb-md ' + (_vm.$q.screen.xs ? 'q-ml-md' : 'q-ml-md')},[_c('label',{staticClass:"text-secondary text-h4",staticStyle:{"font-weight":"700"}},[_vm._v(" Noticias ")])]),(_vm.aBanners && _vm.aBanners.length > 0)?_c('div',{staticClass:"col-12 row justify-center q-mb-md"},[_c('div',{staticClass:"slideshow-container"},[_vm._l((_vm.aBanners),function(item){return _c('div',{key:item.id,class:'mySlides fade row ' +
                (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-mb-md' : 'q-px-md'),style:('border-radius: 20px;' +
                (_vm.$q.screen.xs ? 'height: 300px' : 'height: 366px;'))},[_c('img',{staticClass:"cursor-pointer xs-hide",style:('width: 100%; height: 100%; ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : 'border-radius: 20px;')),attrs:{"src":item.imagen},on:{"click":function($event){return _vm.toLink(item.link, '_self')}}}),_c('img',{staticClass:"cursor-pointer sm-hide md-hide lg-hide xl-hide",style:('width: 100%; height: 100%; ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : 'border-radius: 20px;')),attrs:{"src":item.imagen_mobile},on:{"click":function($event){return _vm.toLink(item.link, '_self')}}})])}),_c('q-icon',{class:'cursor-pointer prev text-white bg-secondary ' +
                (_vm.$q.screen.xs ? 'q-ma-sm' : 'q-ma-xl'),staticStyle:{"font-size":"1.5rem","border-radius":"40px"},attrs:{"name":"keyboard_arrow_left"},on:{"click":function($event){return _vm.plusSlides(-1)}}}),_c('q-icon',{class:'cursor-pointer next bg-secondary text-white ' +
                (_vm.$q.screen.xs ? 'q-ma-sm' : 'q-ma-xl'),staticStyle:{"font-size":"1.5rem","border-radius":"40px"},attrs:{"name":"keyboard_arrow_right"},on:{"click":function($event){return _vm.plusSlides(1)}}})],2)]):_vm._e(),(_vm.aNoticias.length > 0)?_c('div',{staticClass:"col-12 row justify-center q-mb-lg q-mt-md"},[_c('div',{ref:"contenedorNoticias",staticClass:"row justify-start col-12"},_vm._l((_vm.aNoticias),function(noticia){return _c('div',{key:noticia.id,staticClass:"row justify-center col-12 col-sm-6 col-md-3 q-mb-md"},[_c('q-card',{staticClass:"col-11 text_secundario q-pa-sm",staticStyle:{"height":"auto","border-radius":"10px"}},[_c('div',{staticStyle:{"width":"100%","height":"auto"}},[_c('q-img',{staticClass:"cursor-pointer",staticStyle:{"height":"160px !important","border-radius":"10px","z-index":"1"},attrs:{"src":noticia.fotos.image_fulltext},on:{"click":function($event){return _vm.irNoticia(noticia.id, noticia.nombre_web)}}}),_c('span',{staticClass:"absolute q-px-md q-ma-none bg-secondary text-white cursor-pointer",staticStyle:{"bottom":"220px","left":"8px","transform":"translateY(-50%)","font-weight":"bold","border":"none","padding":"8px","z-index":"20"},on:{"click":function($event){return _vm.irCategoria(
                      noticia.categoria.id,
                      noticia.categoria.nombre_web
                    )}}},[_vm._v(" "+_vm._s(noticia.categoria.nombre)+" ")])],1),_c('q-card-section',{staticClass:"q-py-sm bg-white",on:{"click":function($event){return _vm.irNoticia(noticia.id, noticia.nombre_web)}}},[_c('div',{staticClass:"row items-center q-mb-sm"},[_c('label',{staticStyle:{"color":"#000"}},[_vm._v(" "+_vm._s(_vm.fechaPublicacion(noticia.fecha_publicacion))+" ")])])]),_c('q-card-section',{staticClass:"q-py-none",staticStyle:{"height":"70px"},on:{"click":function($event){return _vm.irNoticia(noticia.id, noticia.nombre_web)}}},[_c('div',{staticClass:"col-12 text-bold cursor-pointer row texto_truncado3 items-start",on:{"click":function($event){return _vm.irNoticia(noticia.id, noticia.nombre_web)}}},[_vm._v(" "+_vm._s(noticia.titulo)+" ")])]),_c('q-card-section',{staticClass:"q-px-sm",on:{"click":function($event){return _vm.irNoticia(noticia.id, noticia.nombre_web)}}},[_c('div',{staticClass:"row items-center bg-leer q-pa-sm"},[_c('q-icon',{staticClass:"q-mr-md",attrs:{"size":"sm","name":"newspaper","color":"secundary"}}),_c('p',{staticClass:"q-mb-none cursor-pointer text_secundario text-bold",staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.irNoticia(_vm.noti.id, _vm.noti.nombre_web)}}},[_vm._v(" Leer Noticia ")])],1)])],1)],1)}),0)]):_vm._e(),(_vm.aNoticias.length == 0)?_c('div',{staticClass:"row justify-center q-mt-lg q-mb-lg"}):_vm._e(),(_vm.aNoticias.length > 0 && _vm.total > 8)?_c('div',{staticClass:"col-12 q-pa-lg flex flex-center"},[_c('q-pagination',{attrs:{"unelevated":"","min":1,"max":Math.ceil(_vm.total / 8),"max-pages":_vm.$q.screen.xs ? 1 : 7,"direction-links":true,"color":"secondary"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }